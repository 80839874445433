<template>
  <div class="overflow-x-hidden scroll-smooth">
    <!-- header start-->
    <section class="w-full h-full">
      <div
        class="bg-gradient-to-b from-[#0f2477] to-[#28448d] py-4 lg:py-4 px-5"
      >
        <div class="w-full lg:w-[95%] 2xl:w-[90%] mx-auto">
          <a href="tel:+919717180351">
            <div class="hidden md:flex justify-between items-center">
              <img
                src="@/assets/lpOnline/JO-logo.webp"
                alt="Jain-online"
                srcset=""
                class="md:h-[40px] lg:h-[50px]"
              />
              <p
                class="text-[#f8c300] py-3 text-[20px] rounded-3xl flex gap-2 items-center"
              >
                <img src="@/assets/lpOnline/call-icon.webp" alt="call-icon" />
                +91 9717180351
              </p>
            </div>
          </a>
          <div class="flex justify-between items-center md:hidden">
            <img
              src="@/assets/lpOnline/JO-logo.webp"
              alt=""
              srcset=""
              class="w-[180px] h-[30px]"
            />
            <a href="tel:+919717180351" class="bg-white rounded-full p-1">
              <img
                src="@/assets/lpOnline/call-icon.webp"
                alt="call-icon text-black w-4 h-4"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- header end-->
    <section class="pb-3 md:pb-6 lg:pb-8">
      <div
        class="hidden md:block bg-[#123177] lg:h-[580px] 2xl:h-[600px] px-5 py-2 md:py-0 md:px-4 2xl:p-0 relative"
      >
        <!-- Desktop Banner -->
        <div
          class="w-full lg:w-[95%] 2xl:w-[90%] lg:h-[560px] 2xl:h-[660px] mx-auto"
        >
          <div class="hidden lg:flex lg:justify-between items-center h-full">
            <!-- content -->
            <div class="flex flex-col gap-4 pb-[80px] lg:w-[450px]">
              <h1 class="text-[35px] text-left font-bold text-white">
                Online Degree Programs
              </h1>
              <p
                class="font-semibold 2xl:w-[368px] py-1 rounded-lg text-[18px] bg-[#f8c300]"
              >
                MBA | MCA | M.Com | MA | BBA | B.Com
              </p>
              <ul
                class="text-white flex flex-col gap-3 list-inside"
                v-for="key in keys"
                :key="key"
              >
                <div class="flex gap-2 items-center">
                  <img
                    src="@/assets/lpOnline/white-check.webp"
                    alt=""
                    srcset=""
                    class="w-4 h-4"
                  />
                  <p class="text-[18px]">{{ key }}</p>
                </div>
              </ul>
              <div class="flex gap-2">
                <img
                  src="@/assets/lpOnline/banner-icon1.webp"
                  alt="NAAC-icon"
                  srcset=""
                  class="w-[80px] object-contain"
                />
                <img
                  src="@/assets/lpOnline/banner-icon2.webp"
                  alt="AICTE-icon"
                  srcset=""
                  class="w-[80px] object-contain"
                />
                <img
                  src="@/assets/lpOnline/banner-icon3.webp"
                  alt="AUAP-icon"
                  srcset=""
                  class="w-[80px] object-contain"
                />
                <img
                  src="@/assets/lpOnline/banner-icon4.webp"
                  alt="WUR-icon"
                  srcset=""
                  class="w-[80px] object-contain"
                />
              </div>
            </div>
            <!-- banner image -->
            <div class="flex justify-center">
              <div class="absolute top-0">
                <img
                  src="@/assets/lpOnline/bannerImage.webp"
                  alt="banner-image"
                  class="w-[350px] 2xl:w-[400px] h-[630px] object-cover rounded-b-3xl"
                  srcset=""
                />
              </div>
            </div>

            <!--Enquire form need to put -->
            <FormView />
          </div>
        </div>
      </div>
      <!-- Mobile Banner -->
      <div class="w-full lg:hidden bg-[#123177] h-full">
        <div class="md:w-[500px] mx-auto flex flex-col gap-3 px-5 py-4">
          <div class="flex flex-col gap-2 px-2">
            <!-- content -->
            <h1 class="text-[25px] font-bold text-white">
              Online Degree Programs
            </h1>
            <p class="font-semibold py-1 rounded-lg text-[14px] bg-[#f8c300]">
              MBA | MCA | M.Com | MA | BBA | B.Com
            </p>
            <div class="mt-3 flex flex-col justify-center mx-auto gap-3">
              <ul
                class="text-white pl-4 list-inside"
                v-for="key in keys"
                :key="key"
              >
                <div class="flex gap-2 items-center">
                  <img
                    src="@/assets/lpOnline/white-check.webp"
                    alt=""
                    srcset=""
                    class="w-4 h-4"
                  />
                  <p class="text-[20px]">{{ key }}</p>
                </div>
              </ul>
              <div class="flex justify-center gap-2">
                <img
                  src="@/assets/lpOnline/banner-icon1.webp"
                  alt="NAAC-icon"
                  srcset=""
                  class="w-16 h-16 object-contain"
                />
                <img
                  src="@/assets/lpOnline/banner-icon2.webp"
                  alt="AICTE-icon"
                  srcset=""
                  class="w-16 h-16 object-contain"
                />
                <img
                  src="@/assets/lpOnline/banner-icon3.webp"
                  alt="AUAP-icon"
                  srcset=""
                  class="w-16 h-16 object-contain"
                />
                <img
                  src="@/assets/lpOnline/banner-icon4.webp"
                  alt="WUR-icon"
                  srcset=""
                  class="w-16 h-16 object-contain"
                />
              </div>
            </div>
          </div>
        </div>
        <!--Enquire form need to put -->
        <div class="flex justify-center">
          <FormView />
        </div>
        <!--Enquire form need to put-->
      </div>
      <div class="hidden md:block h-10 w-full bg-white"></div>
    </section>
  </div>
</template>

<script>
import FormView from "@/components/FormView.vue";
export default {
  name: "bannerView",
  components: {
    FormView,
  },
  props: ["heading"],
  data() {
    return {
      degrees: ["MBA", "MCA", "M.Com", "BBA", "B.Com"],
      keys: [
        "40+ In-Demand Electives",
        "Live interactive classes",
        "Industry-aligned curriculum",
        "0 cost EMI option",
        "2000+ hiring corporates",
      ],
    };
  },
};
</script>

<style></style>
