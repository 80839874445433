<template>
  <div>
    <div>
      <!-- Form -->
      <div>
        <div class="w-[350px] h-[500px] bg-[#001c54] pt-5 flex justify-center">
          <div class="flex flex-col gap-3">
            <h1 class="text-[#be8931] text-[23px] text-center">Get A Free Counseling</h1>
            <p class="text-white text-center">in India's Finest University</p>
            <form
              action=""
              @submit.prevent="submitLead"
              class="flex flex-col gap-6"
            >
              <div>
                <input
                  type="text"
                  v-model="fullName"
                  class="py-1 w-[290px] px-2"
                  placeholder="Enter Your Name"
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  v-model="email"
                  class="py-1 w-[290px] px-2"
                  placeholder="Enter Your Email"
                  required
                />
              </div>
              <div>
                <input
                  type="tel"
                  name=""
                  maxlength="10"
                  pattern="[0-9]{10}"
                  v-model="mobileNumber"
                  id=""
                  class="py-1 w-[290px] px-2"
                  placeholder="Enter Your Mobilenumber"
                  required
                />
              </div>
              <div>
                <select
                  name=""
                  id=""
                  @change="showCourses()"
                  v-model="selectedProgram"
                  class="w-[290px] py-2 px-2"
                  aria-placeholder="Select Your Course"
                  required
                >
                  <option value="" disabled>Select Your Course</option>
                  <option
                    class="flex flex-col text-black"
                    :value="program.title"
                    v-for="(program, index) in programs"
                    :key="index"
                  >
                    {{ program.title }}
                  </option>
                </select>
              </div>
              <!-- courses offered -->
              <div>
                <select
                  style="display: none"
                  name="courses"
                  id="courses"
                  v-model="selectedCourse"
                  class="w-[290px] py-2 px-2"
                  aria-placeholder="Select Your Course"
                  required
                >
                  <option value="" disabled>Select Your Course</option>
                  <option
                    class="flex flex-col text-black"
                    :value="course"
                    v-for="course in courseList"
                    :key="course"
                  >
                    {{ course }}
                  </option>
                </select>
              </div>
              <!-- state list -->
              <div>
                <select
                  name=""
                  id=""
                  v-model="selectedState"
                  placeholder="Select Your State"
                  class="w-[290px] py-2 px-2"
                  aria-placeholder="Select Your State"
                  required
                >
                  <option value="" disabled selected>Select Your State</option>
                  <option :value="state" v-for="state in states" :key="state">
                    {{ state }}
                  </option>
                </select>
              </div>
              <div>
                <button class="text-[white] py-2 w-[290px] bg-[#f7860e]">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FormView",
  data() {
    return {
      fullName: "",
      email: "",
      mobileNumber: "",
      selectedProgram: "",
      selectedCourse: "",
      selectedState: "",
      degrees: ["MBA", "MCA", "M.Com", "BBA", "B.Com"],
      courseList: [],

      keys: [
        "40+ In-Demand Elective",
        "Live interactive classes",
        "Industry-aligned curriculum",
        "0 cost EMI option",
        "200+ hiring corporates",
      ],
      states: [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
      ],
      programs: [
        {
          title: "MBA",

          Offered: [
            "Human Resource Management",
            "Finance",
            "Marketing",
            "General Management",
            "Systems and Operations Management",
            "Finance and Marketing",
            "Human Resource Management and Finance",
            "Marketing and Human Resource Management",
            "Project Management",
            "Information Technology Management",
            "Healthcare Management",
            "Logistics & Supply Chain Management ",
            "Business Intelligence & Analytics ",
            "Entrepreneurship and Leadership",
            "International Business Management",
            // "Sports Management",
            "Data Science & Analytics",
            "Digital Marketing & E-commerce",
            "Digital Finance",
            "Integrated Marketing",
            "International Finance (Accredited by ACCA & UK)",
            "Strategic HRM",
            "Banking & Finance ",
          ],
        },
        {
          title: "MCA",

          Offered: [
            "Computer Science & IT",
            "Cyber Security ",
            "Data Analytics",
            "Machine Learning",
            "Full Stack Development",
            "Cloud Computing",
            "Data Science",
            "Artificial Intelligence",
          ],
        },
        {
          title: "M.Com",

          Offered: [
            "Strategic Accounting & Finance (Accredited by CPA & US)",
            "International Finance (Accredited by ACCA & UK)",
            "Accounting & Finance",
          ],
        },
        {
          title: "BBA",

          Offered: ["Human Resource Management", "Finance", "Marketing"],
        },
        {
          title: "B.Com",

          Offered: [
            "International Finance & Accounting (Accredited by ACCA & UK)",
          ],
        },
      ],
    };
  },
  computed: {
    filteredElectives() {
      return this.electives.filter(
        (elective) => elective.program === this.selectedProgram
      );
    },
  },
  methods: {
    showCourses() {
      document.getElementById("courses").style.display = "inline-block";
      console.log(this.selectedProgram);
      this.courseList =
        this.programs.find((item) => item.title === this.selectedProgram)
          ?.Offered || [];
    },
    async submitLead() {
      try {
        // API 1: LeadSquared
        const leadSquaredUrl =
          "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r8e59585e8d73432e41a1f513e834e2f8&secretKey=8705d56dd2458f869d980be09f97986fe80b137b";
        const leadSquaredData = [
          { Attribute: "FirstName", Value: this.fullName },
          { Attribute: "EmailAddress", Value: this.email },
          { Attribute: "Phone", Value: this.mobileNumber },
          {
            Attribute: "mx_Course_Applying_For",
            Value: this.selectedProgram,
          },
          { Attribute: "mx_Specialization", Value: this.selectedCourse },
          { Attribute: "mx_State", Value: this.selectedState },
          { Attribute: "Source", Value: "JAIN ONLINE" },
        ];
        await axios.post(leadSquaredUrl, leadSquaredData, {
          headers: { "Content-Type": "application/json" },
        });

        // API 2: NoPaperForms
        const noPaperFormsUrl =
          "https://api.onlinejain.com/api/npf/lead/create/";
        const noPaperFormsData = {
          name: this.fullName,
          email: this.email,
          country_dial_code: "+91",
          mobile: this.mobileNumber,
          campus: this.selectedProgram,
          course: this.selectedCourse,
          state: this.selectedState,
          source: "College sati",
        };
        await axios.post(noPaperFormsUrl, noPaperFormsData);

        // If both API calls succeed, redirect to Thank You page
        this.$router.push("/lp/online/thankyou");
      } catch (error) {
        // If any API call fails, handle the error here
        console.error("Submission failed", error);
        alert("Submission failed");
      }
    },
  },
};
</script>

<style scoped></style>
