<template>
  <div class="md:py-20 bg-gradient-to-b from-[#0F2377] to-[#00102F] py-5">
    <section class="max-w-7xl mx-auto w-full min-h-[600px] md:mt-10">
      <div class="p-5 lg:p-4 2xl:p-0 flex flex-col gap-2">
        <div class="flex justify-between items-center">
          <h2 class="text-[#F8C300] md:text-[35px] text-[24px]">
            Alumni Speak
          </h2>
        </div>

        <div
          class="flex flex-col md:flex-row flex-wrap gap-5 md:justify-center lg:pl-5 2xl:pl-0"
        >
          <div
            class="lg:w-[290px] response lg:h-[493px] w-full md:w-[35%] relative"
            v-for="data in visibleAlumniData"
            :key="data.id"
          >
            <template v-if="data.id == is_active_video">
              <video
                class="w-[320px] md:w-[290px]"
                width="290"
                height="493"
                :src="data.linkVideo"
                controls
                title="Video player"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></video>
            </template>
            <template v-else>
              <div @click="is_active_video = data.id">
                <div class="relative">
                  <div
                    class="absolute bottom-0 left-0 w-full h-full"
                    style="
                      background: transparent
                        linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
                        0% no-repeat padding-box;
                      box-shadow: 0px 1px 6px #00000029;
                      border-radius: 14px;
                      opacity: 1;
                      height: 150px;
                    "
                  ></div>
                  <div class="h-[493px]">
                    <img
                      :src="data.bannerImg"
                      alt=""
                      class="w-full rounded-2xl h-full"
                    />
                  </div>
                  <div class="flex justify-center items-center">
                    <img
                      src="@/assets/SuccessStories/Watch/play.svg"
                      alt=""
                      srcset=""
                      class="absolute top-[200px] md:top-[190px] w-[75px] h-[75px]"
                    />
                  </div>
                </div>
                <div class="flex justify-center">
                  <img
                    :src="data.smallImg"
                    alt=""
                    class="rounded-full w-[120px] h-[120px] absolute bottom-[17%] border p-2 border-[#F8C300]"
                  />

                  <p class="text-white absolute bottom-[20px]">
                    <span class="text-[24px] font-bold">{{ data.name }}</span>
                    <br />
                    {{ data.subject }}
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="py-5 flex justify-center items-center">
          <button
            v-if="visibleVideosCount < aluminaiData.length"
            @click="loadMore"
            class="bg-[#F8C300] text-[#001C54] w-full md:w-[160px] text-[16px] py-[13px] px-4 rounded-3xl button-cta"
          >
            Load More
          </button>
          <button
            v-if="visibleVideosCount > 4"
            @click="loadLess"
            class="bg-[#F8C300] text-[#001C54] w-full md:w-[160px] text-[16px] py-[13px] px-4 rounded-3xl button-cta"
          >
            Load Less
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "WatchView",
  data() {
    return {
      is_active_video: "0",
      visibleVideosCount: 4,
      aluminaiData: [
        {
          id: 1,
          bannerImg: require("@/assets/SuccessStories/Watch/JO-Testimonial-Mobile-290.97x493-Anirudh-Chawat-May_07.webp"),
          smallImg: require("@/assets/SuccessStories/Watch/JO-Testimonialrounded-114px-Anirudh-Chawat-May_07.webp"),
          name: "Aniruddh Chawat",
          subject: "MBA HR and Finance",
          linkVideo:
            "https://api.onlinejain.com/media/9_16_Final/Aniruddh_9_16.mp4",
        },
        {
          id: 2,
          bannerImg: require("@/assets/SuccessStories/Watch/JO-Testimonial-Mobile-290.97x493-Debasmita-May_07.webp"),
          smallImg: require("@/assets/SuccessStories/Watch/JO-Testimonialrounded-114px-Debasmita-May_07.webp"),
          name: "Debasmita Roy",
          subject: " BBA HR",
          linkVideo:
            "https://api.onlinejain.com/media/9_16_Final/Debasmita_9_16.mp4",
        },
        {
          id: 3,
          bannerImg: require("@/assets/SuccessStories/Watch/JO-Testimonial-Mobile-290.97x493-Hiba-May_07.webp"),
          smallImg: require("@/assets/SuccessStories/Watch/JO-Testimonialrounded-114px-Hiba-May_07.webp"),
          name: "Heba Kousar",
          subject: " MBA HR and Finance",
          linkVideo:
            "https://api.onlinejain.com/media/9_16_Final/Heba_9_16.mp4",
        },
        {
          id: 4,
          bannerImg: require("@/assets/SuccessStories/Watch/JO-Testimonial-Mobile-290.97x493-Kalpita-May_07.webp"),
          smallImg: require("@/assets/SuccessStories/Watch/JO-Testimonialrounded-114px-Kalpita-May_07.webp"),
          name: "Kalpitha S",
          subject: "MBA Finance and Marketing",
          linkVideo:
            "https://api.onlinejain.com/media/9_16_Final/Kalpita_9_16.mp4",
        },
        {
          id: 5,
          bannerImg: require("@/assets/SuccessStories/Watch/JO-Testimonial-Mobile-290.97x493-Yash-May_07.webp"),
          smallImg: require("@/assets/SuccessStories/Watch/JO-Testimonialrounded-114px-yash-May_07.webp"),
          name: "Yashwanth S",
          subject: "B.Com Accounting and Finance",
          linkVideo:
            "https://api.onlinejain.com/media/9_16_Final/Yash_9_16.mp4",
        },
      ],
    };
  },
  computed: {
    visibleAlumniData() {
      return this.aluminaiData.slice(0, this.visibleVideosCount);
    },
  },
  methods: {
    loadMore() {
      this.visibleVideosCount = Math.min(
        this.visibleVideosCount + 4,
        this.aluminaiData.length
      );
    },
    loadLess() {
      this.visibleVideosCount = Math.max(this.visibleVideosCount - 4, 4);
    },
  },
};
</script>

<style></style>
