import { createRouter, createWebHistory } from "vue-router";

import LpView from "@/views/LpView.vue";
import LpViewThankyou from "@/views/LpViewThankyou.vue";

const routes = [
  {
    path: "/",
    redirect: "/lp/online",
  },
  {
    path: "/lp/online",
    name: "LpView",
    component: LpView,
  },
  {
    path: "/lp/online/thankyou",
    name: "LpThankyou",
    component: LpViewThankyou,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
